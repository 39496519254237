import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { apiPostRequest } from "../_shared/api";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import { toast } from "react-toastify";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "auto",
}));

interface Event {
  id: number;
  event_name: string;
  group_id: number;
}

interface CreateEventPopupProps {
  open: boolean;
  onClose: () => void;
  events: Event[];
}

const CreatePromoCodePopup: React.FC<CreateEventPopupProps> = ({
  open,
  onClose,
  events,
}) => {
  const [promoCode, setPromoCode] = useState("");
  const [timesToUse, setTimesToUse] = useState(1);
  const [discountValue, setDiscountValue] = useState(1);
  const [date, setDate] = useState("");
  const [discountType, setDiscountType] = useState<any>("");
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);

  const validateFields = (): boolean => {
    if (!promoCode.trim()) {
      toast("Please enter a promo code", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (timesToUse <= 0 || !Number.isInteger(timesToUse)) {
      toast("Please enter a valid number of times to use", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (discountValue <= 0 || isNaN(discountValue)) {
      toast("Please enter a valid discount value", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (!date.trim()) {
      toast("Please select an expiry date", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (!discountType) {
      toast("Please select a discount type", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (!selectedEvent) {
      toast("No event selected", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (!userDetails) {
      toast("User details not found", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    return true;
  };

  const handleSelectedEvent = (event: Event) => {
    setSelectedEvent(event);
  };

  const handleSave = async () => {
    if (validateFields()) {
      const createEventRes = await apiPostRequest(
        "/events/add/promo_code",
        {
          group_id: selectedEvent?.group_id,
          expiry_date: date,
          code: promoCode,
          quantity: timesToUse,
          discount_type: discountType,
          discount_value: discountValue,
          event_id: selectedEvent?.id,
        },
        {
          auth: userDetails.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        }
      );

      if (createEventRes?.code === "00") {
        toast(createEventRes.msg ?? "Promo code added", {
          type: ErrorSeverityEnum.success,
        });
        window.location.reload();
      } else {
        toast(createEventRes.msg ?? "Promo code creation failed", {
          type: ErrorSeverityEnum.error,
        });
      }

      onClose();
    }
  };

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData && open) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };

  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(EventsEnum.CheckLoginStatus, (data: boolean) => {
        initFunct();
      })
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <Typography variant="h6">Create Promo Code</Typography>
        <StyledIconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <FormHelperText>Select Event</FormHelperText>
          <Select
            value={selectedEvent?.id || ""}
            onChange={(e) => {
              const event = events.find((evt) => evt.id === e.target.value);
              if (event) handleSelectedEvent(event);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Select an event" }}
          >
            <MenuItem value="" disabled>
              Select an event
            </MenuItem>
            {events.map((event) => (
              <MenuItem key={event.id} value={event.id}>
                {event.event_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          label="Promo Code"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          type="date"
          label="Expiration Date"
          value={date}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setDate(e.target.value)}
        />
        <TextField
          fullWidth
          type="number"
          margin="normal"
          label="Quantity"
          value={timesToUse}
          onChange={(e) => setTimesToUse(parseInt(e.target.value))}
        />
        <FormControl sx={{ marginBottom: 5 }} fullWidth>
          <FormHelperText>Discount type</FormHelperText>
          <Select
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Select a discount type" }}
          >
            {discountType === "" && (
              <MenuItem value="" disabled>
                Select a discount type
              </MenuItem>
            )}
            <MenuItem value={"flat_rate"}>Flat Rate</MenuItem>
            <MenuItem value={"percentage"}>Percentage</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          type="number"
          margin="normal"
          label="Discount Value"
          value={discountValue}
          onChange={(e) => setDiscountValue(parseInt(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePromoCodePopup;
