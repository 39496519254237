import Carousel from "react-multi-carousel";
import CategoryCard from "./CategoryCard";
import { useEffect } from "react";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";

const Categories = ({categories}:{categories:Array<any>}) => {
    const initfunction = async () => {
        const res = await apiGetRequest(urls.categories);
        // if (res?.data != null) {
        //   setEvents(res.data);
        // }
        // if (featuredRes?.data != null) {
        //   setFeaturedEvents(featuredRes?.data);
        // }
      };
    useEffect(()=>{
        // initfunction();
    },[]);
    return ( <Carousel
        swipeable={false}
        draggable={false}
       showDots={true}
        responsive={{
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
          }
        }}
      >
        {categories.map((x)=><CategoryCard category_details={x}/>)}
        
      </Carousel> );
}
 
export default Categories;