import { Typography, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const styles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
};

const checkmarkStyle: React.CSSProperties = {
  fontSize: 100,
  color: 'green', // You can use your desired color here
};

const PaymentSuccessPage = () => {
  const [countdown, setCountdown] = useState<number>(10);
  const navigate = useNavigate();


  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      const searchParams = new URLSearchParams(window.location.search);
      const loc = searchParams.get("loc");
      if(loc === 'card' ){
        navigate('/');
      }else{
        navigate(-2);
      }
    }
  }, [countdown, navigate]);

  return (
    <div style={styles}>
      <div style={checkmarkStyle}>&#10004;</div>
      <Typography variant="h5">Payment Successful</Typography>
      <Typography variant="h6">
      We would be sending you an SMS or Email receipt shortly.
      </Typography>
      <Typography variant="subtitle1">
        Redirecting in {countdown} seconds...
      </Typography>
      <CircularProgress />
    </div>
  );
};

export default PaymentSuccessPage;
