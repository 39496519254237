import styled from "styled-components";
import CalendarSvg from "../../_shared/assets/svg/Calender.svg";
import ClockSvg from "../../_shared/assets/svg/Clock.svg";
import LocationPinSvg from "../../_shared/assets/svg/Location.svg";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import VaryingWidthImage from "../_shared/components/imageViewer";
import { Column, Row } from "../_shared/styledComponents";

const CategoryCard = ({ category_details }: { category_details: any }) => {
  return (
    <EventCardWrapper>
      <Box
        sx={{
          // height: "260px",
          width: "100%",
          objectFit: "cover",
          borderRadius: "15px",
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          top: "0",
        }}
      >
        <VaryingWidthImage
          imageUrl={category_details.image_link}
          blurImageUrl={category_details.image_link}
        />
        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            right: "10",
            zIndex: "10",
            height: "60px",

            width: "100%",
            background: "rgba(0,0,0,0.8)",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {category_details.category_name}
        </div>
      </Box>
    </EventCardWrapper>
  );
};

export default CategoryCard;

const EventCardWrapper = styled.div`
  overflow: hidden;
  position: relative;
  background: #f0f2f2;
  border-radius: 15px;
  height: 210px;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  cursor: pointer !important;
  margin-bottom: 3rem;
`;
const BodyWrapper = styled.div`
  background: #f0f2f2;
  margin: 15px;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.span`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #090a0d !important;
  margin-bottom: 10px;
`;
const BodyText = styled.span`
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #5f6462 !important;
`;

const Footer = styled.div`
  border-top: 1px solid #cacdd1;
  padding: 15px;
  display: flex;
  justify-content: space-between;
`;
const PriceText = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: right;
  color: #0caf6d;
`;
