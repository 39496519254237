import styled from 'styled-components';

interface NavButtonProps {
    svgUrl: string;
  }

// Styled component for the button
const NavButton = styled.button<NavButtonProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: ${({ svgUrl }) => `url(${svgUrl}) no-repeat center center / contain`};
  color: white;
  border: none;
  height: 120px;
  padding: 10px;
  cursor: pointer;
  z-index: 20;
  &:disabled {
    background: rgba(0, 0, 0, 0.2);
    cursor: default;
  }
`;

const PrevButton = styled(NavButton)`
  left: 10px;
`;

const NextButton = styled(NavButton)`
  right: 10px;
`;

export { PrevButton, NextButton };
