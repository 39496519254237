import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { isNumber } from '../services';
import { apiGetRequest } from '../api';
import LeftSvg from "../assets/svg/left.svg";
import RightSvg from "../assets/svg/right.svg";
import { NextButton, PrevButton } from './navButtons';


const Container = styled('div')<{ borderRadius: string }>((props) => ({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  borderRadius: props.borderRadius,
}));

const ImageContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

const ForegroundImage = styled('img')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  maxHeight: '100%',
  zIndex: 10,
});

const BackgroundImage = styled('img')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  minWidth: '100%',
  minHeight: '100%',
  filter: 'blur(10px)',
});

interface VaryingWidthImageProps {
  imageUrl: string;
  blurImageUrl: string;
  imageList?: string[];
  imageFit?: 'width' | 'height';
  borderRadius?: string;
  type?: string;
  disableSlider?: boolean;
}
// Custom deep comparison function
const isEqual = (value: any, other: any): boolean => {
  if (value === other) return true;

  if (typeof value !== 'object' || value === null || typeof other !== 'object' || other === null) {
    return false;
  }

  const keysValue = Object.keys(value);
  const keysOther = Object.keys(other);

  if (keysValue.length !== keysOther.length) return false;

  for (let key of keysValue) {
    if (!Object.prototype.hasOwnProperty.call(other, key) || !isEqual(value[key], other[key])) {
      return false;
    }
  }

  return true;
};

const useDeepCompareEffect = (callback: React.EffectCallback, dependencies: any[]) => {
  const currentDependenciesRef = useRef<any[]>();

  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies;
  }

  useEffect(callback, [currentDependenciesRef.current]);
};

const VaryingWidthImage: React.FC<VaryingWidthImageProps> = ({
  imageUrl,
  blurImageUrl,
  imageList = [],
  imageFit = 'width',
  borderRadius,
  disableSlider = true
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fgImage, setFgImage] = useState<string>('');
  const [bgImage, setBgImage] = useState<string>('');
  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  const fetchImage = async (id: any) => {
    const res = await apiGetRequest(`/media/events/${id}`, undefined, false);
    if (res?.code == "00") {
      return res.data;
    }
    return '';
  };

  const fetchImages = async () => {

    if (isNumber(imageUrl)) {
      const fgImg = await fetchImage(imageUrl);
      setFgImage(fgImg);
    } else {
      setFgImage(imageUrl);
    }

    if (isNumber(blurImageUrl)) {
      const bgImg = await fetchImage(blurImageUrl);
      setBgImage(bgImg);
    } else {
      setBgImage(blurImageUrl);
    }

    const loadedImageList = await Promise.all(imageList.map(async (img: any) => {
      if (isNumber(img.image_link)) {
        return await fetchImage(img.image_link);
      }
      return img;
    }));

    setLoadedImages(loadedImageList);
  };

  useDeepCompareEffect(() => {
    fetchImages();
  }, [imageUrl, blurImageUrl, imageList]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : loadedImages.length - 1;
      setBgImage(loadedImages[newIndex]);
      return newIndex;
    });
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex < loadedImages.length - 1 ? prevIndex + 1 : 0;
      setBgImage(loadedImages[newIndex]);
      return newIndex;
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex < loadedImages.length - 1) {
          setFgImage(loadedImages[prevIndex + 1]);
          setBgImage(loadedImages[prevIndex + 1]);
          return prevIndex + 1;
        } else {
          clearInterval(intervalId);
          return prevIndex;
        }
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, [loadedImages]);

  return (
    <Container borderRadius={borderRadius ?? '15px'}>

      <ImageContainer>

        {(loadedImages.length > 0 && disableSlider) ? (
          <>
            <ForegroundImage
              src={loadedImages[currentIndex]}
              alt=""
              style={{ width: 'auto', height: 'auto' }}
            />
            <BackgroundImage src={bgImage} alt="" />
          </>
        ) : (
          <>
            <ForegroundImage
              src={fgImage}
              alt=""
              style={{ width: 'auto', height: 'auto' }}
            />
            <BackgroundImage src={bgImage} alt="" />
          </>
        )}
        {(loadedImages.length > 1  && disableSlider) && (
          <>
            <PrevButton svgUrl={LeftSvg} onClick={handlePrev}>&lt;</PrevButton>
            <NextButton svgUrl={RightSvg} onClick={handleNext}>&gt;</NextButton>
          </>
        )}
      </ImageContainer>
    </Container>
  );
};

export default VaryingWidthImage;
