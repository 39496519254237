import React, { useState, ChangeEvent } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

const Input = styled('input')({
  display: 'none',
});

interface ImageUploadProps {
  onImageUpload: (image: string) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onImageUpload }) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileExtension = file.name.split('.').pop();
      const timestamp = Date.now();
      const newFileName = `${timestamp}.${fileExtension}`;
      const newFile = new File([file], newFileName, { type: file.type });

      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        setImagePreview(result);
        console.log(result);

        onImageUpload(result); // Send the image path to the parent component
      };
      reader.readAsDataURL(newFile);
    }
  };

  return (
    <Box sx={{ textAlign: 'center', mt: 2, width: "100%" }}>
      <label htmlFor="image-upload-general">
        <Input
          accept="image/*"
          id="image-upload-general"
          type="file"
          onChange={handleImageChange}
        />
        <Button variant="outlined" component="span" sx={{ borderRadius: "100px", width: "100%", height: "55px" }}>
          Upload Image
        </Button>
      </label>
    </Box>
  );
};

export default ImageUpload;


// import React, { useState, ChangeEvent } from 'react';
// import { Box, Button, Typography } from '@mui/material';
// import { styled } from '@mui/system';

// const Input = styled('input')({
//   display: 'none',
// });

// interface ImageUploadProps {
//   onImageUpload: (image: string) => void;
// }

// const ImageUpload: React.FC<ImageUploadProps> = ({ onImageUpload }) => {
//   const [imagePreview, setImagePreview] = useState<string | null>(null);

//   const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const result = reader.result as string;
//         setImagePreview(result);
//         console.log(result);

//         onImageUpload(result); // Send the image path to the parent component
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   return (
//     <Box sx={{ textAlign: 'center', mt: 2, width:"100%" }}>
//       <label htmlFor="image-upload-general">
//         <Input
//           accept="image/*"
//           id="image-upload-general"
//           type="file"
//           onChange={handleImageChange}
//         />
//         <Button variant="outlined" component="span" sx={{borderRadius:"100px", width:"100%", height:"55px"}}>
//           Upload Image
//         </Button>
//       </label>
      
//     </Box>
//   );
// };

// export default ImageUpload;
