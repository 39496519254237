import styled from "styled-components";
import { Column, Row } from "../_shared/styledComponents";
import { BodyText2, HeaderGreenTopBar, HeaderText } from "../_shared/styled";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VaryingWidthImage from "../_shared/components/imageViewer";
import QrCodeGenerator from "../Event_Admin/QrCodeGenerator";
import { useEffect, useState } from "react";
import { apiGetRequest, apiPostRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { useLocation, useNavigate } from "react-router-dom";
import ShareSvg from "../_shared/assets/svg/send2.svg";
import AuthPage, { StyledTextField } from "../Auth/AuthPage";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import { toast } from "react-toastify";
import { CountryCodes } from "../_shared/types";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { isNumber } from "../_shared/services";

const PaymentConfirmation = () => {
  const [receiptDetails, setReceiptDetails] = useState<any>({});
  const [eventId, setEventId] = useState<any>({});
  const [showLogin, setShowLogin] = useState(false);
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  // const [recPhoneNumber, setRecipientPhone] = useState<any>(null);
  const [country, setCountry] = useState<CountryCodes>({}); // Flag to indicate paste event
  const [countryCode, setCountryCode] = useState<any>(""); // State to hold the selected country code
  const [phoneNumber, setPhoneNumber] = useState<any>(""); // State to hold the selected country code
  const [userDetails, setUserDetails] = useState<any>(null);
  const location = useLocation();
  const navigator = useNavigate();
  const { eventData } = location.state || { eventData: {} };
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));

  const initFunction = async () => {
    setUserDetails(null);
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
      }
    }
  };

  const initFunct = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const receipt_id = searchParams.get("receipt_id");
    const event_id = searchParams.get("event_id");

    if (receipt_id) {
      const res = await apiGetRequest(`${urls.receiptDetails}${receipt_id}`);

      if (res?.data != null) {
        // console.log(res.data, receipt_id,res.data.find((x: any)=> x.purchased_ticket_id == receipt_id))
        setReceiptDetails(
          res.data.find(
            (x: any) => x.purchased_ticket_id == receipt_id.split("-")[1]
          )
        );
      }
      if (res?.msg != "00") {
        // toast(res?.msg ?? "Fetched data successfully", {
        //   type: ErrorSeverityEnum.success,
        // });
      } else {
        toast(res?.msg ?? "Failed to fetch receipt data", {
          type: ErrorSeverityEnum.error,
        });
      }
    }
    if (event_id) {
      setEventId(event_id);
    }
  };

  const transferTicket = async () => {
    setOpenTransferDialog(false);
    const parts = countryCode.split(" ", 2);
    const code = parts[0];
    const selectedCountry = countryCode.substring(parts[0].length + 1);
    const internationalNumber = `${code}${
      phoneNumber[0] == "0" ? phoneNumber.substring(1) : phoneNumber
    }`;
    const transferTicketRes = await apiPostRequest(
      "/purchased_ticket/share_ticket",
      {
        recipient_phone: internationalNumber,
        purchased_ticket_id_list: [receiptDetails?.purchased_ticket_id],
        country: selectedCountry,
      },
      {
        auth: userDetails.access_token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      }
    );

    if (transferTicketRes?.code == "00") {
      // toast(transferTicketRes?.msg ?? "Ticket Transferred", {
      //   type: ErrorSeverityEnum.success,
      // });

      navigator("/my-tickets");
    } else {
      setOpenTransferDialog(true);
      toast(transferTicketRes?.msg ?? "Failed to transfer ticket", {
        type: ErrorSeverityEnum.error,
      });
    }
  };

  const handleCountryCodeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCountryCode(event.target.value);
  };
  const getCountryCodes = async () => {
    const adRes = await apiGetRequest(`${urls.country}`, {
      auth: undefined,
      content_type: ContentTypeEnum.APPLICATION_JSON,
    });

    if (adRes?.code === "00") {
      const countries: CountryCodes = {};
      [...adRes.data].forEach((element: any) => {
        countries[`${element.country_code}`] = {
          code: `${element.dialing_prefix}`.substring(1),
          name: `${element.country_name_in_english}`,
        };
      });

      setCountry(countries);
    }
  };

  useEffect(() => {
    getCountryCodes();
  }, []);

  useEffect(() => {
    initFunct();
    initFunction();
  }, []);

  function formatDate(dateString: string): string {
    if (!dateString) {
      return '""';
    }

    let date: Date;
    if (dateString.startsWith("today")) {
      date = new Date();
      if (dateString.includes("+")) {
        const daysToAdd = parseInt(dateString.split("+")[1], 10);
        date.setDate(date.getDate() + daysToAdd);
      }
    } else {
      date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return '""';
      }
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const eventDate = receiptDetails?.event_date ?? "";
  const eventEndDate = receiptDetails?.end_date ?? "";

  const startDate = formatDate(eventDate);
  const endDate = formatDate(eventEndDate);

  function formatTime(dateTimeString: string): string {
    if (!dateTimeString) {
      return '""';
    }

    let date: Date;
    if (dateTimeString.startsWith("today")) {
      date = new Date();
      if (dateTimeString.includes("+")) {
        const daysToAdd = parseInt(dateTimeString.split("+")[1], 10);
        date.setDate(date.getDate() + daysToAdd);
      }
    } else {
      date = new Date(dateTimeString);
      if (isNaN(date.getTime())) {
        return "";
      }
    }

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  const startTime = formatTime(eventDate);
  const endTime = formatTime(eventEndDate);

  const sanitizeTimezone = (timezone?: string): string => {
    if (!timezone) {
      return "";
    }
    return timezone.replace(",", "/").replace(" ", "");
  };

  const calTimezone = sanitizeTimezone(receiptDetails?.event_timezone);

  return (
    <PaymentConfirmationWrapper style={{padding:isMatch?"5rem 1rem":"5rem"}}>
      <Column>
        <HeaderGreenTopBar />
        <HeaderText>PAYMENT CONFIRMATION</HeaderText>
      </Column>
      {!receiptDetails ? (
        <></>
      ) : (
        <>
          <Box
            sx={{
              height: "50vh",
              width: "100%",
              objectFit: "cover",
              margin: "5rem 0",
            }}
          >
            <VaryingWidthImage
              imageUrl={receiptDetails?.poster}
              blurImageUrl={receiptDetails?.poster}
            />
          </Box>

          <EventWrapper style={{ borderRadius: "15px" }}>
            <Column style={{ width: "35%", margin: "2rem" }}>
              <HeaderGreenTopBar />
              <HeaderText style={{ marginBottom: "2rem" }}>
                EVENT INFORMATION
              </HeaderText>
              <GridContainer>
                <GridItem>
                  <BodyHeader>Event Name</BodyHeader>
                  <BodyText>{receiptDetails?.event_name}</BodyText>
                </GridItem>
                <GridItem>
                  <BodyHeader>Event Date</BodyHeader>
                  <BodyText>
                    {(receiptDetails?.event_date ?? "").length > 0
                      ? receiptDetails?.event_date
                          .split(" ")
                          .slice(0, 4)
                          .join(" ")
                      : ""}
                  </BodyText>
                </GridItem>
                <GridItem>
                  <BodyHeader>Event Time</BodyHeader>
                  <BodyText>
                    {(receiptDetails?.event_date ?? "").length > 0
                      ? receiptDetails?.event_date
                          .split(" ")
                          .slice(4, 6)
                          .join(" ")
                      : ""}
                  </BodyText>
                </GridItem>
                <GridItem>
                  <BodyHeader>Event Address</BodyHeader>
                  <BodyText>{receiptDetails?.venue}</BodyText>
                </GridItem>
                {receiptDetails?.description && (
                  <GridItem>
                    <BodyHeader>Event Description</BodyHeader>
                    <BodyText>{receiptDetails?.description}</BodyText>
                  </GridItem>
                )}
              </GridContainer>
            </Column>
            {!isMatch && (
              <div
                style={{
                  height: "20rem",
                  borderLeft: "dashed 2px #595F67",
                  width: "10px",
                  margin: "2rem 0",
                }}
              />
            )}
            <Column
              style={{
                // borderLeft: isMatch?'none':"dashed 2px #595F67",
                paddingLeft: isMatch ? "0" : "0",
                // marginRight: "3rem",
                margin: "2rem",
              }}
            >
              <HeaderGreenTopBar />
              <HeaderText style={{ marginBottom: "2rem" }}>
                PAYMENT DETAILS
              </HeaderText>
              <GridContainer>
                <GridItem>
                  <BodyHeader>{receiptDetails?.ticket_name}</BodyHeader>
                  <BodyText>
                    {receiptDetails?.price == 0
                      ? "Free"
                      : `${receiptDetails?.currency}${receiptDetails?.price}`}
                  </BodyText>
                </GridItem>
              </GridContainer>

              <div
                style={{
                  marginTop: "40px",
                  fontWeight: "bold",
                  display: "block",
                }}
              >
                <HeaderGreenTopBar />
                <HeaderText style={{ marginBottom: "2rem" }}>
                  Add Events To Calendar
                </HeaderText>
                <div style={{ marginTop: "15px" }}>
                  <AddToCalendarButton
                    name={receiptDetails.event_name}
                    options={["Apple", "Google", "iCal", "Outlook.com"]}
                    location={receiptDetails.venue}
                    startDate={startDate}
                    startTime={startTime}
                    endDate={startDate}
                    endTime={endTime}
                    timeZone={calTimezone}
                    description={receiptDetails.description}
                    hideBackground
                    buttonsList
                    size="1"
                  ></AddToCalendarButton>
                </div>
              </div>
            </Column>
          </EventWrapper>
          <EventWrapper
            style={{
              margin: "2rem 0",
              marginBottom: "0.5rem",
            }}
          >
            <HeaderText style={{ fontSize: "15px", margin: "15px" }}>
              QR Codes
            </HeaderText>
          </EventWrapper>
          <Row
            mainAxisAlignment="space-between"
            sx={{ flexWrap: "wrap", gap: "2rem" }}
          >
            <Row spacing={20}>
              <QrCodeGenerator
                url={`${receiptDetails?.purchased_ticket_id},${receiptDetails?.group_id},${eventId}`}
                size={100}
                height={100}
                width={100}
                color="#000"
              />
              <Column>
                <Row spacing={10}>
                  <BodyHeader>Ticket #:</BodyHeader>
                  <BodyText>{receiptDetails?.purchased_ticket_id}</BodyText>
                </Row>
                <Row spacing={10}>
                  <BodyHeader>Type:</BodyHeader>
                  <BodyText>{receiptDetails?.ticket_name}</BodyText>
                </Row>
                <Row spacing={10}>
                  <BodyHeader>Price:</BodyHeader>
                  <BodyText>
                    {receiptDetails?.price == 0
                      ? "Free"
                      : `${receiptDetails?.currency}${receiptDetails?.price}`}
                  </BodyText>
                </Row>
                {receiptDetails?.status == "TRANSFERRED" && (
                  <BodyText>This ticket has been transferred</BodyText>
                )}
              </Column>
            </Row>
            {receiptDetails?.status == "PURCHASED" && (
              <Row style={{ marginRight: "10rem" }}>
                <Row
                  crossAxisAlignment="center"
                  spacing={10}
                  onClick={() => {
                    setOpenTransferDialog(true);
                  }}
                >
                  <RhsTransferText style={{ cursor: "pointer" }}>
                    Transfer Ticket
                  </RhsTransferText>
                  <RhsSubContainer>
                    <img
                      style={{
                        width: "34px",
                        height: "34px",
                      }}
                      src={ShareSvg}
                    />
                  </RhsSubContainer>
                </Row>
              </Row>
            )}
          </Row>
          <EventWrapper
            style={{
              margin: "2rem 0",
              marginBottom: "0.5rem",
              // padding: "15px",
            }}
          >
            <HeaderText style={{ fontSize: "15px", margin: "15px" }}>
              Next Steps
            </HeaderText>
          </EventWrapper>
          <BodyText2>
            You will receive an SMS and/or Email with your receipt confirmation.
            If you have any questions, reach out to support@qasa.me.
          </BodyText2>
          {/* <EventWrapper
            style={{
              margin: "2rem 0",
              marginBottom: "0.5rem",
              // padding: "15px",
            }}
          >
            <HeaderText style={{ fontSize: "15px", margin: "15px" }}>
              Confirmation
            </HeaderText>
          </EventWrapper>
          <Column>
          <StyledTextField
            sx={{
              "& fieldset": {
                border: " 1px solid #79C2D8",
                borderRadius: "100rem",
              },
              width: "100%",
              maxWidth: "400px",
            }}
            InputProps={{
              inputProps: {
                style: {
                  textAlign: "left",
                  borderRadius: "100rem",
                  background: "white",
                  height: "20px",
                },
              },
            }}
            onChange={(e) => {
              // console.log(e.target.value);
              // setFirstName(e.target.value);
            }}
            margin="normal"
            id="firstName"
            label="Email"
            name="firstName"
          />
          
          <Button
        variant="contained"
        sx={{
          width: "100%",
          maxWidth: "400px",
          height: "48px",
          borderRadius: "100rem",
          backgroundColor: "#0CAF6D",
          marginTop: "70px",
        }}
        onClick={() => {}}
      >
        Send Confirmation
      </Button> 
          </Column>*/}
          

          <StyledDialog
            open={openTransferDialog}
            onClose={() => setOpenTransferDialog(false)}
          >
            <HeaderText color="white">TRANSFER TICKET</HeaderText>
            <Row spacing={20} style={{ marginTop: "1rem" }}>
              <div
                style={{
                  background: "white",
                  padding: "10px 20px",
                  height: "120px",
                  borderRadius: "10px",
                }}
              >
                <QrCodeGenerator
                  url={`${receiptDetails?.purchased_ticket_id},${receiptDetails?.group_id},${eventId}`}
                  size={100}
                  height={100}
                  width={100}
                  color="#000"
                />
              </div>
              <Column>
                <Row spacing={10}>
                  <BodyHeader style={{ color: "white" }}>Ticket #:</BodyHeader>
                  <BodyText style={{ color: "white" }}>
                    {receiptDetails?.purchased_ticket_id}
                  </BodyText>
                </Row>
                <Row spacing={10}>
                  <BodyHeader style={{ color: "white" }}>Type:</BodyHeader>
                  <BodyText style={{ color: "white" }}>
                    {receiptDetails?.ticket_name}
                  </BodyText>
                </Row>
                <Row spacing={10}>
                  <BodyHeader style={{ color: "white" }}>Price:</BodyHeader>
                  <BodyText style={{ color: "white" }}>
                    {receiptDetails?.price == 0
                      ? "Free"
                      : `${receiptDetails?.currency}${receiptDetails?.price}`}
                  </BodyText>
                </Row>
              </Column>
            </Row>

            <BodyHeader style={{ color: "white", marginTop: "2rem" }}>
              CAUTION ⓘ
            </BodyHeader>
            <BodyText style={{ color: "white" }}>
              You are about to transfer a purchased ticket. Please note
              transferred tickets cannot be returned or refunded to you.{" "}
            </BodyText>

            <FormControlLabel
              control={
                <RedCheckbox
                  checked={agreeToTerms}
                  onChange={(event) => setAgreeToTerms(event.target.checked)}
                />
              }
              label="I agree. I’d like to transfer my ticket"
              style={{ color: "rgba(121, 194, 216, 1)", marginTop: "1rem" }}
            />

            <Row crossAxisAlignment="center" spacing={10}>
              <Container
                sx={{
                  width: "100%",
                  maxWidth: "500px",
                  background: "rgba(240, 240, 240, 1)",
                  borderRadius: "100rem",
                  height: "55px",
                  marginTop: "10px",
                }}
              >
                <Box display="flex" alignItems="center">
                  {/* Country code dropdown */}
                  <TextField
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    select
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                    variant="outlined"
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    {/* Default empty option */}
                    <MenuItem value="" disabled>
                      Select Country Code
                    </MenuItem>

                    {/* Country code options */}
                    {Object.keys(country).map((key: string) => (
                      <MenuItem
                        value={`${country[key].code} ${country[key].name}`}
                      >{`${country[key].code} (${country[key].name})`}</MenuItem>
                    ))}
                    {/* Add more country codes as needed */}
                  </TextField>

                  {/* Spacer between dropdown and TextField */}
                  <Box width={10} />

                  {/* Actual text field for phone number */}
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      //@ts-ignore
                      setPhoneNumber(!isNaN(event.target.value) ? event.target.value.slice(0, 12) : phoneNumber);
                    }}
                    sx={{
                      "& fieldset": { border: "none" },
                      "& .MuiInputLabel-shrink": {
                        margin: "19px auto",
                        position: "absolute",
                        left: "0",
                        top: "-3px",
                        width: "150px", // Need to give it a width so the positioning will work
                        background: "none", // Add a white background as below we remove the legend that had the background so text is not meshing with the border
                      },
                    }}
                    value={phoneNumber}
                  />
                </Box>
              </Container>

              <Button
                variant="contained"
                disabled={!phoneNumber || !agreeToTerms || !countryCode}
                sx={{
                  width: "100%",
                  maxWidth: "180px",
                  height: "55px",
                  borderRadius: "100rem",
                  backgroundColor: "#0CAF6D",
                }}
                onClick={() => {
                  // navigate(`/e/${eventDetails?.purl}`);
                  transferTicket();
                }}
              >
                Transfer Ticket
              </Button>
            </Row>

            {/* <Row crossAxisAlignment="center" spacing={20} style={{marginTop:"1rem"}}>
          <StyledTextField
          value={recPhoneNumber}
            variant="outlined"
            sx={{
              "& fieldset": {
                border: "1px solid #79C2D8",
                borderRadius: "1000px",
                margin: 0,
              },
              width: "100%",
              maxWidth: "600px",
              margin: 0,
            }}
            InputProps={{
              type:"tel",
              inputProps: {
                style: {
                  textAlign: "left",
                  borderRadius: "100rem",
                  height: "20px",
                  background: "white",
                },
              },
            }}
            onChange={(e) => {
              if(e.target.value == ""){
                setRecipientPhone(null)
              }else{
                setRecipientPhone(e.target.value.slice(0,12));
              }
            }}
            margin="normal"
            id="rPhone"
            label="Recipient's phone e.g. 233550000001"
            name="rPhone"
          />
          <Button
        
            variant="contained"
            disabled={!recPhoneNumber || !agreeToTerms}
            sx={{
              width: "100%",
              height: "55px",
              borderRadius: "100rem",
              backgroundColor: "#0CAF6D",
            }}
            onClick={() => {
              // navigate(`/e/${eventDetails?.purl}`);
              transferTicket();
            }}
          >
            Transfer Ticket
          </Button>
        </Row> */}
          </StyledDialog>
        </>
      )}
    </PaymentConfirmationWrapper>
  );
};

export default PaymentConfirmation;

const PaymentConfirmationWrapper = styled.div`
`;

const EventWrapper = styled.div`
  /* padding: 2rem; */
  overflow: hidden;
  width: 100%;
  background-color: #edf2f7;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const BodyHeader = styled.span`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #5f6462;
`;

const BodyText = styled.span`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #5f6462;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  width: 100%;
`;

const GridItem = styled.div`
  text-align: center; /* Center align text */
  display: flex;
  flex-direction: column;
`;

const RhsSubContainer = styled.div`
  background-color: #0caf6d;
  width: 30px;
  height: 30px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
`;

const RhsTransferText = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: right;
  color: rgba(37, 94, 135, 1);
`;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background: rgba(37, 94, 135, 1);
    border-radius: 16px 0 16px 16px;
    padding: 2rem;
    color: white;
  }
`;

// Create a styled version of the Checkbox
const RedCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "red",
  "&.Mui-checked": {
    color: "red",
  },
}));
