import { useEffect, useState } from "react";
import DataGridTable from "../_shared/components/datagridTable";
import { Container } from "../_shared/styledComponents";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { GridColDef } from "@mui/x-data-grid";
import NoResults from "../_shared/components/NoResults";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const MyTicketSales = () => {
  const columns: GridColDef[] = [
    { field: "event_names", headerName: "Event Name", width: 200 },
    { field: "ticket_name", headerName: "Ticket Name", width: 200 },
    { field: "ticket_id", headerName: "Ticket ID", width: 100 },
    { field: "user_name", headerName: "User Name", width: 200 },
    { field: "purchase_date", headerName: "Purchase Date", width: 200 },
    { field: "price", headerName: "Price", width: 120 },
    { field: "promo_code", headerName: "Promo Code", width: 120 },
  ];
  const [rows, setRows] = useState<any>([]);
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);

  const location = useLocation();
  const { eventData } = location.state || { eventData: {} };

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);

        const myEventsRes = await apiGetRequest(`${urls.myTickets}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        if (myEventsRes?.code === "00") {
          const filteredEvents = myEventsRes.data.filter((event: { event_name: any; }) => event.event_name === eventData.event_name);
          setRows(
            filteredEvents.flatMap((event: any, index: number) =>
              event.ticket_details.flatMap((ticket: any) =>
                ticket.user_metrics.flatMap((user: any, userIndex: number) =>
                  user.purchase_metrics.map(
                    (purchaseMetric: any, purchaseIndex: number) => ({
                      id: `${index}-${ticket.ticket_id}-${userIndex}-${purchaseIndex}`,
                      event_names: event.event_name,
                      ticket_name: ticket.ticket_name,
                      ticket_id: ticket.ticket_id,
                      user_name: user.name,
                      purchase_date: purchaseMetric.date_created,
                      price: `${purchaseMetric.currency}${purchaseMetric.price}`,
                      promo_code: purchaseMetric.promo_code,
                    })
                  )
                )
              )
            )
          );
        }
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };
  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);
  return (
    <Container>
      <Typography variant="h4" sx={{ marginBottom: 10 }}>
        Event Sales Details
      </Typography>
      <Helmet>
        <title>Event Sales Details</title>
      </Helmet>

      {userDetails ? (
        <DataGridTable columns={columns} rows={rows} page="ticket_sales" />
      ) : (
        <div style={{ marginTop: "20vh" }}>
          <NoResults
            header={"Not Logged In!"}
            body={"Kindly login to view this page"}
          />
        </div>
      )}
    </Container>
  );
};

export default MyTicketSales;
