import { useState, useEffect, useCallback } from "react";
import { EventRegister } from "react-native-event-listeners";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { Container } from "../_shared/styledComponents";
import { EventsEnum } from "../_shared/types";
import { GridColDef } from "@mui/x-data-grid";
import NoResults from "../_shared/components/NoResults";
import DataGridTable from "../_shared/components/datagridTable";
import { Box } from "@mui/material";

const MyTickets = () => {
  const [userDetails, setUserDetails] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const columns: GridColDef[] = [
    {
      field: 'ticket_name',
      headerName: 'Ticket Name',
      width: 200,
      valueGetter: (params) => params.row.ticket_details.ticket_name,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ height: 30, width: 30, objectFit: 'cover', marginRight: 5 }}
            alt="image"
            src={params.row.ticket_details.ticket_image}
          />
          <div>{params.row.ticket_details.ticket_name}</div>
        </div>
      ),
    },
    { field: 'event_date', headerName: 'Event Date', width: 250 },
    { field: 'ticket_event_name', headerName: 'Event Name', width: 200 },
    { field: 'venue', headerName: 'Venue', width: 200 },
    { field: 'quantity', headerName: 'Quantity', width: 100 },
    { field: 'status', headerName: 'Status', width: 100 },
  ];

  const fetchTickets = useCallback(async (token: string) => {
    try {
      // console.log("Fetching tickets with token:", token);
      const myEventsRes = await apiGetRequest(`${urls.myPurchasedTickets}`, {
        auth: token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      });

      if (myEventsRes?.code === "00") {
        setRows(
          myEventsRes.data.reverse().map((ticket: any, index: number) => ({
            id: index + 1,
            ticket_event_name: ticket.event_name,
            event_date: ticket.event_date,
            venue: ticket.venue,
            quantity: ticket.quantity,
            status: ticket.status,
            ticket_details: {
              ticket_name: ticket.ticket_name,
              ticket_image: ticket.ticket_image,
            },
            receipt_id: `${ticket.receipt_id}-${ticket.id}`,
          }))
        );
      } else {
        console.error("Failed to fetch tickets:", myEventsRes);
        setError("Failed to fetch tickets. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
      setError("An error occurred while fetching tickets. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const initFunct = useCallback(async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
        await fetchTickets(userDetailsObject?.access_token);
      } catch (error) {
        console.error("Error parsing user details:", error);
        setError("There was an error loading your data. Please try logging in again.");
        setIsLoading(false);
      }
    } else {
      console.warn("No user details found in localStorage.");
      setIsLoading(false);
    }
  }, [fetchTickets]);

  useEffect(() => {
    initFunct();

    const subscribedEvent = EventRegister.addEventListener(
      EventsEnum.CheckLoginStatus,
      (data: boolean) => {
        if (data) {
          initFunct();
        }
      }
    );

    return () => {
      if (typeof subscribedEvent === "string") {
        EventRegister.removeEventListener(subscribedEvent);
      }
    };
  }, [initFunct]);

  if (isLoading) {
    return <Container>Loading...</Container>;
  }

  if (error) {
    return (
      <Container>
        <NoResults header={"Error"} body={error} />
      </Container>
    );
  }

  return (
    <Container>
      {userDetails ? (
        <DataGridTable
          columns={columns}
          rows={rows}
          clickable={true}
          page="my_tickets"
        />
      ) : (
        <div style={{ marginTop: "20vh" }}>
          <NoResults
            header={"Not Logged In!"}
            body={"Kindly login to view this page"}
          />
        </div>
      )}
    </Container>
  );
};

export default MyTickets;
