import styled from "styled-components";
import SideBarCloseButton from "../_shared/assets/svg/SideBarCloseButton.svg";
import { useEffect, useState } from "react";
import { BodyText, BodyText2, HeaderText } from "../_shared/styled";
import { Column } from "../_shared/styledComponents";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { formatPhoneNumber } from "../_shared/functions";
import { useNavigate } from "react-router-dom";
import VaryingWidthImage from "../_shared/components/imageViewer";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../_shared/types";

const SidePanel = ({ userDetails }: { userDetails: any }) => {
  const [openSidePanel, setOpenSidePanel] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [propUserDetails, setUserDetails] = useState<any>(userDetails);
  const [headerHeight, setHeaderHeight] = useState<any>(75);

  const handleSettingsClick = () => {
    setShowSubMenu(!showSubMenu);
  };

  useEffect(()=>{
    const elementHeight = document.getElementById('appHeader')?.clientHeight ?? 75;
    setHeaderHeight(elementHeight);
  },[])

  const handleSubMenuClick = (path: string) => {
    navigate(path);
    setOpenSidePanel(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setUserDetails(null);
    navigate("/");
    EventRegister.emit(EventsEnum.CheckLoginStatus, true);
  };

  return (
    <SidePanelWrapper openSidePanel={openSidePanel} style={{height:`calc(100vh - ${headerHeight}px)`}}>
      <div style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
      <SidePanelBody>
        <Column
          crossAxisAlignment="center"
          style={{
            marginTop: "3rem",
            display: openSidePanel ? "block" : "none"
            , overflowY: "auto" 
          }}
        >
          
          <List disablePadding sx={{ width: "100%", marginTop: "2rem" }}>
            <ListItem
              disablePadding
              sx={{ marginLeft: "35px", marginBottom: "2rem" }}
            >
              <Column>
                <Avatar
                  sx={{ marginBottom: "1rem", width: "60px", height: "60px" }}
                >
                  {/* {`${userDetails.name}`[0]} */}
                  <VaryingWidthImage blurImageUrl={propUserDetails?.avatar} imageUrl={propUserDetails?.avatar}/>

                </Avatar>

                <BodyText color="#5F6462">Welcome:</BodyText>
                <BodyText2 color="#5F6462">{propUserDetails?.name}</BodyText2>
                <BodyText2 color="#5F6462">
                  {formatPhoneNumber(propUserDetails?.phone)}
                </BodyText2>
              </Column>
            </ListItem>
            <Divider />

{/*             
                        <ListItemButton
              sx={{
                background: window.location.pathname == "/#" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                handleSubMenuClick("#");
              }}
            >
              <StyledListItemText
                primary="My Events"
                isSelected={window.location.pathname == "/#"}
              />
              {window.location.pathname == "/#" && <GreenBar />}
            </ListItemButton>
            <Divider /> */}
            <ListItemButton
              sx={{
                background:
                  window.location.pathname == "/my-events" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                handleSubMenuClick( "/my-events");
              }}
            >
              <StyledListItemText
                primary="My Events"
                isSelected={window.location.pathname ==  "/my-events"}
              />
              {window.location.pathname == "/my-events" && <GreenBar />}
            </ListItemButton>
            <Divider />

            <ListItemButton
              sx={{
                background:
                  window.location.pathname == "/create-event" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                handleSubMenuClick("/create-event");
              }}
            >
              <StyledListItemText
                primary="Create Event"
                isSelected={window.location.pathname == "/create-event"}
              />
              {window.location.pathname == "/create-event" && <GreenBar />}
            </ListItemButton>
            <Divider />


            <ListItemButton
              sx={{
                background:
                  window.location.pathname == "/event-admin" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                handleSubMenuClick("/event-admin");
              }}
            >
              <StyledListItemText
                primary="Event Admin"
                isSelected={window.location.pathname == "/event-admin"}
              />
              {window.location.pathname == "/event-admin" && <GreenBar />}
            </ListItemButton>
            <Divider />

            <ListItemButton
              sx={{
                background:
                  window.location.pathname == "/promo-codes" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                handleSubMenuClick("/promo-codes");
              }}
            >
              <StyledListItemText
                primary="Promo Codes"
                isSelected={window.location.pathname == "/promo-codes"}
              />
              {window.location.pathname == "/promo-codes" && <GreenBar />}
            </ListItemButton>
            <Divider />

            {/* <ListItemButton
              sx={{
                background:
                  window.location.pathname == "/create-event" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                handleSubMenuClick("/create-event");
              }}
            >
              <StyledListItemText
                primary="Create Event"
                isSelected={window.location.pathname == "/create-event"}
              />
              {window.location.pathname == "/create-event" && <GreenBar />}
            </ListItemButton>
            <Divider /> */}


            <ListItemButton
              sx={{
                background: window.location.pathname == "/analytics" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                navigate("/analytics");
              }}
            >
              <StyledListItemText
                primary="Analytics and Reports"
                isSelected={window.location.pathname == "/analytics"}
              />
              {window.location.pathname == "/analytics" && <GreenBar />}
            </ListItemButton>
            <Divider />

            {/* <ListItemButton
              sx={{
                background: window.location.pathname == "/#" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                handleSubMenuClick("#");
              }}
            >
              <StyledListItemText
                primary="My Payments"
                isSelected={window.location.pathname == "/#"}
              />
              {window.location.pathname == "/#" && <GreenBar />}
            </ListItemButton>
            <Divider /> */}
           
            <ListItemButton
              sx={{
                background:
                  window.location.pathname == "/event-sales" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                handleSubMenuClick("/event-sales");
              }}
            >
              <StyledListItemText
                primary="Event Sales"
                isSelected={window.location.pathname == "/event-sales"}
              />
              {window.location.pathname == "/event-sales" && <GreenBar />}
            </ListItemButton>
            <Divider />
            {/* <ListItemButton sx={{ background:window.location.pathname =="/event-sales" ? "#E1E6EA":"",padding:"0", paddingLeft:"20px", height:"71px" }} onClick={() => {
                  handleSubMenuClick("/ticket-sales");
                }}>
                <StyledListItemText primary="Event Sales Details" />
                {window.location.pathname =="/ticket-sales" &&<GreenBar/>}

            </ListItemButton>
            <Divider /> */}
            <ListItemButton
              sx={{
                background:
                  window.location.pathname == "/my-tickets" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                handleSubMenuClick("/my-tickets");
              }}
            >
              <StyledListItemText
                primary="My Tickets"
                isSelected={window.location.pathname == "/my-tickets"}
              />
              {window.location.pathname == "/my-tickets" && <GreenBar />}
            </ListItemButton>
            <Divider />
            {/* <ListItemButton
              sx={{
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={() => {
                window.location.replace(
                  "https://www.qasa.me/pages/contact"
                );
              }}
            >
              <StyledListItemText
                primary="Help"
                isSelected={false}
              />
              {false && <GreenBar />}
            </ListItemButton>
            <Divider /> */}

            <ListItemButton
              sx={{
                background: window.location.pathname == "/#" ? "#E1E6EA" : "",
                padding: "0",
                paddingLeft: "20px",
                height: "71px",
              }}
              onClick={handleSettingsClick}
            >
              <StyledListItemText
                primary="Settings"
                isSelected={window.location.pathname == "/#"}
              />
              {/* <div className="arrow">
                <ArrowDownwardIcon style={{ color: "#0CAF6D" }} />
              </div> */}
              {window.location.pathname == "/#" && <GreenBar />}
            </ListItemButton>
            <Divider />
            {showSubMenu && (
              <List>
                <ListItem
                  button
                  onClick={() => handleSubMenuClick("#")}
                >
                  <StyledListItemText
                    primary="Privacy"
                    isSelected={window.location.pathname == "/#"}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleSubMenuClick("#")}
                >
                  <StyledListItemText
                    primary="Notifications"
                    isSelected={window.location.pathname == "/#"}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    window.location.replace(
                      "https://www.qasa.me/pages/contact"
                    );
                  }}
                >
                  <StyledListItemText
                    primary="Help"
                    isSelected={window.location.pathname == "/#"}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleLogout()}
                >
                  <StyledListItemText
                    primary="Logout"
                    isSelected={window.location.pathname == "/#"}
                  />
                </ListItem>
                {/* Add more submenu items as needed */}
              </List>
            )}

          </List>
          
        </Column>
      </SidePanelBody>
      </div>
      <SidePanelOpenContainer
        src={SideBarCloseButton}
        onClick={() => setOpenSidePanel(!openSidePanel)}
      />
    </SidePanelWrapper>
  );
};

export default SidePanel;

const SidePanelWrapper = styled.div<{ openSidePanel: boolean }>`
  height: calc(100vh - 75px);
  margin-top: 75px;
  width: ${(props) => (props.openSidePanel ? "100%" : "42px")};
  max-width: 300px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5000;
  transition: width 0.3s ease; /* Add transition for smooth animation */
`;
const SidePanelBody = styled.div`
  height: calc(100vh - 75px);
  width: 100%;
  background-color: #edf2f7;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  border: 1px solid #cacdd1;
  overflow:auto;
`;
const SidePanelOpenContainer = styled.img`
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  margin-right: 5px;
  position: absolute;
  right: 0;
  top: 10px;
`;
const StyledListItemText = styled(ListItemText)<{ isSelected: boolean }>`
  .MuiTypography-root {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 12px;
    text-align: left;
    color: ${(props) => (props.isSelected ? "#0CAF6D" : "#090a0d")};
    margin-left: 20px;
  }
`;

export const GreenBar = styled.div`
  background-color: #0caf6d;
  width: 5px;
  height: 71px;
`;
