import React from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  useGridSelector,
  useGridApiContext,
  gridPageCountSelector,
  GridPagination,
  GridRowParams,
  GridValidRowModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import {
  LinearProgress,
  Stack,
  TablePaginationProps,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import { Container } from "../styledComponents";
import NoResults from "./NoResults";
import { useNavigate } from "react-router-dom";

interface Props<T extends GridValidRowModel> {
  rows: GridRowsProp;
  columns: GridColDef[];
  loading?: boolean;
  clickable?: boolean;
  page: string;
  onClick?: (params: GridRowParams<T>) => void;
}

export default function Table<T extends GridValidRowModel>(props: Props<T>) {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const { loading, onClick } = props;
  const navigate = useNavigate();

  function Pagination({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  }

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarExport />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }


  const renderHyperlink = (params: any) => {
    return (
      <a className="event-link">
        {params.value}
      </a>
    );
  };

  return (
    <TableWrapper style={{ height: 750, width: "100%" }}>
      <DataGrid
        rows={props.rows}
        columns={props.columns.map((column, index) => ({
          ...column,
          renderCell:
            column.field === "event_name" || column.field === "ticket_name" && props.page === "my_tickets" ? renderHyperlink : column.renderCell,
          cellClassName:
            column.field === "event_name" || column.field === "ticket_name" && props.page === "my_tickets" ? "first-column-text" : "",
        }))}
        onRowClick={(props.clickable ?? true)?(params, _) => {
          if(props.page === "event_sales"){
            navigate("/ticket-sales", { state: { eventData: params.row } });
          } else if (props.page === "my_tickets"){
            window.open(`/payment-confirmation?receipt_id=${params.row.receipt_id}`, '_blank');
            // navigate("/payment-confirmation", { state: { eventData: params.row } });
          }
          onClick && onClick(params);
        }: ()=>{}}
        rowHeight={55}
        pagination
        slots={{
          pagination: CustomPagination,
          toolbar: CustomToolbar,
          noResultsOverlay: () => (
            <NoResults
              header={"No Result Found!"}
              body={"Nothing matched what you were looking for"}
            />
          ),
          noRowsOverlay: () => (
            <NoResults
              header={"Nothing yet!"}
              body={"Nothing matched what you were looking for"}
            />
          ),
        }}
        slotProps={{
          toolbar: {
            printOptions: {
              fields: props.columns.map((column: any) => column.field),
            },
            csvOptions: { disableToolbarButton: false },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 200 },
          },
        }}
        loading={loading}
        autoPageSize
        columnHeaderHeight={42}
        density="comfortable"
        disableRowSelectionOnClick
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 15, 20]}
        className="data-grid"
        classes={{
          columnHeader: "column-header",
          withBorderColor: "with-border-color",
          toolbarContainer: "toolbar-container",
          columnHeaderTitleContainer: "column-header-title-container",
          "cell--withRenderer": "cell--withRenderer",
          row: "row",
          footerContainer: "footer-container",
        }}
        getRowClassName={(params) => (params.id === 1 ? "first-row" : "")}
      />
    </TableWrapper>
  );
}

const TableWrapper = styled(Container)(({ theme }) => ({
  ".data-grid": {
    border: "none !important",
    color: theme.typography.body1.color,
  },
  ".column-header": {
    background: theme.palette.background.paper,
    fontSize: "12px",
    fontFamily: "Gilroy-Bold",
  },
  ".with-border-color": {
    borderColor: "rgb(229, 231, 234)",
  },
  ".MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  ".toolbar-container": {
    marginBottom: "18px",
    button: {
      padding: "10px 20px",
      borderRadius: "20px",
    },
    ".MuiTextField-root": {
      ".MuiInput-root": {
        border: `1px solid ${theme.palette.primary.light} !important`,
        borderRadius: "4px",
        padding: "0px 12px",
        svg: {},
      },
      ".MuiInput-root:before": {
        border: "none !important",
      },
    },
  },
  ".column-header-title-container": {
    ".MuiCheckbox-root": {
      svg: {},
      "&.Mui-checked": {
        svg: {},
      },
    },
  },
  ".cell--withRenderer": {
    ".Mui-checked": {
      svg: {},
    },
    svg: {},
  },
  ".row": {
    fontSize: "12px",
    cursor: "pointer",
  },
  ".first-row": {
    cursor: "pointer",
  },
  ".first-column-text": {
    color: "blue",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  ".footer-container": {
    border: "none !important",
    marginTop: "32px",
    ".MuiDataGrid-selectedRowCount, .MuiTablePagination-displayedRows": {
      fontSize: "12px",
      padding: "8px 12px",
      borderRadius: "8px",
    },
    ".MuiPagination-root": {
      ".MuiPaginationItem-root.Mui-selected": {},
    },
  },
}));
