import { useState, useEffect } from "react";
import { EventRegister } from "react-native-event-listeners";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { Container } from "../_shared/styledComponents";
import { EventsEnum } from "../_shared/types";
import { GridColDef } from "@mui/x-data-grid";
import NoResults from "../_shared/components/NoResults";
import DataGridTable from "../_shared/components/datagridTable";
import { Box } from "@mui/material";

const Analytics = () => {
    const [subscribedEvent, setSubscribedEvent] = useState<any>();
    const [userDetails, setUserDetails] = useState<any>(null);
    const columns: GridColDef[] = [
      { field: 'event_name', headerName: 'Event Name', width: 200 },
      { field: 'number_of_orders', headerName: 'Number Of Orders', width: 250 },
      { field: 'gross_sales', headerName: 'Gross Sales', width: 200 },
      { field: 'net_sales', headerName: 'Net Sales', width: 200 },
      { field: 'ticket_count', headerName: 'Ticket Count', width: 100 },
      { field: 'payment_owed', headerName: 'Payment Owed', width: 100 },
      
    ];
  const [rows, setRows] = useState<any>([]);
  
  
    const initFunct = async () => {
      const storedData = localStorage.getItem("userDetails");
  
      if (storedData) {
        try {
          const userDetailsObject = JSON.parse(storedData);
  
          setUserDetails(userDetailsObject);
  
          const myEventsRes = await apiGetRequest(`${urls.analytics}`, {
            auth: userDetailsObject?.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          });
  
          if (myEventsRes?.code === "00") {
            setRows(myEventsRes.data.map((event: any, index: number) => ({
              id: index + 1,
              event_name: event.event_name,
              number_of_orders: event.number_of_orders,
              gross_sales: event.gross_sales_amount,
              net_sales: event.net_sales_amount,
              ticket_count: event.ticket_count,
              payment_owed: event.payment_owed
            })))
            
          }
        } catch (error) {
          localStorage.clear();
          EventRegister.emit(EventsEnum.CheckLoginStatus, true);
        }
      }
    };
    
    useEffect(() => {
      initFunct();
      setSubscribedEvent(
        EventRegister.addEventListener(
          EventsEnum.CheckLoginStatus,
          (data: boolean) => {
            initFunct();
          }
        )
      );
      return () => {
        EventRegister.removeEventListener(subscribedEvent);
      };
    }, []);
    return ( <Container>
      {userDetails?<DataGridTable columns={columns} rows={rows} clickable={true} page="analytics"/>: <div style={{marginTop:"20vh"}}>
      <NoResults
              header={"Not Logged In!"}
              body={"Kindly login to view this page"}
              
            />
        </div>}
    </Container> );
}
 
export default Analytics;