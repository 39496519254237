import React, { forwardRef } from 'react';
import { TextField, styled } from '@mui/material';

type Props = {
  onChange: (value: string) => void;
  label?: string;
  type?: string;
  hint?: string;
  multiline?: boolean;
  value?: number|string;
};

const InputField = forwardRef<HTMLInputElement, Props>(({
  onChange, label, hint, multiline = false, type, value, ...rest
}, ref) => {
  return (
    <StyledTextField
      variant="outlined"
      multiline={multiline}
      minRows={multiline ? 5 : 1}
      onChange={(e) => onChange(e.target.value)}
      margin="normal"
      label={label}
      placeholder={hint}
      type={type}
      fullWidth
      value={value}
      inputRef={ref}
      {...rest}
    />
  );
});

export default InputField;

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    textAlign: 'left',
  },
  '& .MuiInputLabel-shrink': {
    margin: '0 auto',
    position: 'absolute',
    left: '0',
    top: '-3px',
    width: '150px', // Provide a width so the positioning works
    background: 'none', // Ensure the label text does not mesh with the border
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: "25px",
    '& fieldset': {
      borderColor: '#79C2D8',
    },
    '&:hover fieldset': {
      borderColor: '#79C2D8', // Hover border color
    },
    '&.Mui-focused fieldset': {
      borderColor: '#79C2D8', // Focused border color
    },
  },
}));
