import { useState, useEffect } from "react";
import { EventRegister } from "react-native-event-listeners";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { Column, Container, Row } from "../_shared/styledComponents";
import { EventsEnum } from "../_shared/types";
import { GridColDef } from "@mui/x-data-grid";
import NoResults from "../_shared/components/NoResults";
import DataGridTable from "../_shared/components/datagridTable";
import { HeaderGreenTopBar, HeaderOption, HeaderText } from "../_shared/styled";
import CreatePromoCodePopup from "./addPromoCode";

const PromoCodes = () => {
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);
  // const [eventDetails, setEventDetails] = useState<any>(null);
  const [addPromoCode, setAddPromoCode] = useState<any>(false);
  const columns: GridColDef[] = [
    { field: "promo_event_name", headerName: "Event Name", width: 200 },
    { field: "promo_code_name", headerName: "Promo Code Name", width: 200 },
    { field: "quantity", headerName: "Quantity", width: 250 },
    { field: "discount_type", headerName: "Discount Type", width: 200 },
    { field: "discount_value", headerName: "Discount Value", width: 200 },
  ];
  const [rows, setRows] = useState<any>([]);
  const [eventDetails, setEventDetails] = useState<any>([]);

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);

        const myPromoCodeRes = await apiGetRequest(`${urls.myEvents}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        if (myPromoCodeRes?.code === "00") {
          const events = myPromoCodeRes.data.map((event: any) => ({
            id: event.id,
            event_name: event.event_name,
            group_id: event.group_id,
          }));

          setEventDetails(events);

          let globalIdCounter = 1;

          const rows = myPromoCodeRes.data.flatMap((event: any) =>
            event.promo_codes.map((promo_code: any) => ({
              id: globalIdCounter++,
              promo_event_name: event.event_name,
              promo_code_name: promo_code.code,
              quantity: promo_code.quantity,
              discount_type: promo_code.discount_type,
              discount_value: promo_code.discount_value,
            }))
          );

          setRows(rows);
        }
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };

  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);
  return (
    <>
      <Row
        mainAxisAlignment="space-between"
        crossAxisAlignment="end"
        sx={{ marginTop: "3rem" }}
      >
        <Column>
          <HeaderGreenTopBar />
          <HeaderText>Promo Codes</HeaderText>
        </Column>

        <Row
          crossAxisAlignment="center"
          spacing={10}
          sx={{ ":hover": { cursor: "pointer" } }}
        >
          <HeaderOption
            onClick={() => {
              setAddPromoCode(true);
            }}
          >
            Create Promo Code
          </HeaderOption>
        </Row>
      </Row>
      <br></br>
      <Container>
        {userDetails ? (
          <DataGridTable
            columns={columns}
            rows={rows}
            clickable={true}
            page="promo_codes"
          />
        ) : (
          <div style={{ marginTop: "20vh" }}>
            <NoResults
              header={"Not Logged In!"}
              body={"Kindly login to view this page"}
            />
          </div>
        )}
      </Container>
      <CreatePromoCodePopup
        open={addPromoCode}
        onClose={() => {
          setAddPromoCode(false);
        }}
        events={eventDetails}
      />
    </>
  );
};

export default PromoCodes;
