import React, { ReactNode } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';

interface Option {
  value: string | number;
  label: string;
}

interface DropdownComponentProps {
  options: Option[];
  selectedValue: string | number;
  onChange: (event: SelectChangeEvent<string | number>, child: ReactNode) => void;
  label: string;
}

const DropdownComponent: React.FC<DropdownComponentProps> = ({
  options,
  selectedValue,
  label,
  onChange
}) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="dynamic-select-label">{label}</InputLabel>
      <Select
        labelId="dynamic-select-label"
        id="dynamic-select"
        value={selectedValue}
        onChange={onChange}
        label="Options"
        sx={{background: '#FAFBFC', borderRadius: '28px', '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#79C2D8',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#79C2D8',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#79C2D8',}}}

      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownComponent;
