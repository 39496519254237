export interface EventItem {
    event_name: string,
    poster: string,
    event_date: string,
    display_status: string|null|undefined,
    venue: string,
    purl: string,
    id: number,
    image_sliders: [],
    event_id: number
    event_timezone: any;
};

export enum DisplayStatusEnum{
    draft = "draft",
    cancelled = "cancelled",
}