export function getUploadablePartOfB64(imageData: string) {
    if (imageData && isBase64String(imageData)) return imageData.split(",")[1];
    else return null;
}

// @ts-ignore
export function isBase64String(data: string | null | undefined) {
    if (data) return data.substr(0, 5) === "data:";
    else return false;
}

export function isNumber(value: any): boolean {
    // Convert the value to a number using parseFloat
    const number = parseFloat(value);
    
    // Check if the result is a number and not NaN
    return !isNaN(number) && isFinite(number);
}