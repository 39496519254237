import React, { useState } from 'react';
import { Autocomplete, TextField, Checkbox } from '@mui/material';

interface Category {
  id: number;
  category_description: string;
  category_name: string;
  date_added: string;
  date_updated: string | null;
  image_link: string;
  status: string;
}

const CategoriesMultiSelectDropdown = ({setCategories, categories}:{setCategories: any, categories: any}) => {
//   const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);

  const handleCategoryChange = (event: React.ChangeEvent<{}>, value: Category[]) => {
    setSelectedCategories(value);
    setCategories(value);
  };

  return (
    <div style={{ marginTop: '16px', width: '100%', marginBottom: '10px' }}>
      <Autocomplete
              sx={{ '.MuiOutlinedInput-root': { borderRadius: '100rem' } }}
        multiple
        options={categories}
        getOptionLabel={(option) => option.category_name}
        value={selectedCategories}
        onChange={handleCategoryChange}
        renderInput={(params) => <TextField {...params} variant="outlined" label="Select Categories" />}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              checked={selected}
              style={{ marginRight: 8 }}
            />
            {option.category_name}
          </li>
        )}
      />
    </div>
  );
};

export default CategoriesMultiSelectDropdown;
