import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../EventDetails/css/eventDetails.css";
import { apiGetRequest, apiPostRequest, apiPutRequest } from "../_shared/api";
import { HeaderGreenTopBar } from "../_shared/styled";

import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { loadStripe } from "@stripe/stripe-js";
import { baseReceiptUrl, urls } from "../_shared/constants";
import VaryingWidthImage from "../_shared/components/imageViewer";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../_shared/types";
import { toast } from "react-toastify";
import { Container, InputAdornment, styled, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { StyledTextField } from "../Auth/AuthPage";
import {
  Box,
  Typography,
  Button,
  Modal,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import { FullWidthColumn, Row } from "../_shared/styledComponents";
import { CheckOutOptions } from "./checkoutOptions";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import {
  TicketInfo,
  UserWallet,
  Ticket,
  EventData,
  CustomFields,
  PurchasedTicket,
} from "./interfaces";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { DisplayStatusEnum } from "../Home/_helpers/types";
import { Helmet } from "react-helmet";
import MetaTags from "../_shared/components/helmets";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

function EventDetails() {
  const [searchParams] = useSearchParams();
  const [eventDetails, setEventDetails] = useState<EventData>();
  const [eventTickets, setEventTickets] = useState<Array<Ticket>>([]);
  const [purchasedTicket, setPurchasedTicket] = useState<
    Array<PurchasedTicket>
  >([]);
  const [userWallets, setUserWallets] = useState<Array<UserWallet>>([]);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [qasaPin, setQasaPin] = useState<any>("");
  const [paymentMode, setPaymentMode] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [eventPoster, setEventPoster] = useState("");
  const [email, setEmail] = useState("");
  const [network, setNetwork] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState("");
  const [promoCodeUsed, setPromoCodeUsed] = useState(false);
  const [value, setValue] = useState<number>(1);
  const [retry_count, setRetryCount] = useState<number>(1);
  const [showPaymentStripe, setShowStripePayment] = useState(false);
  const [showPaymentHubtel, setShowHubtelPayment] = useState(false);
  const [stripeElementLoaded, setStripeElementLoaded] = useState(false);
  const [stripeOptions, setStripeOptions] = useState<any>({});
  const [adData, setAdData] = useState<any>(null);
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [hubtelUrlToRender, setHubtelUrlToRender] = useState<any>();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const [ticketSelected, setTicketSelected] = useState(false);

  const stripePromise = loadStripe(
    process.env.REACT_APP_NODE_ENV == "development"
      ? process.env.REACT_APP_STRIPE_DEV_KEY ?? ""
      : process.env.REACT_APP_STRIPE_PROD_KEY ?? ""
  );
  const [end_date, setEndDate] = useState("Thu, 23 May 2024 18:30:00 GMT");
  const [event_date, setEventDate] = useState("Thu, 23 May 2024 18:30:00 GMT");
  const [event_time, setEventTime] = useState("16:30:00 GMT");
  const [localEndDate, setLocalEndDate] = useState("");
  const [localEventDate, setLocalEventDate] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [checkout, setCheckout] = useState(false);
  const [ticketCounts, setTicketCounts] = useState(false);
  const [ticketList, setTicketList] = useState(eventTickets.map(() => 0));
  const [subTotal, setSubTotal] = useState(0);
  const [actualAmount, setActualAmount] = useState(0);
  const [fees, setTicketsFee] = useState(0);
  const [currency, setCurrency] = useState("");
  const [ticketData, setTicketData] = useState<TicketInfo[]>([]);
  const [customFields, setCustomFields] = useState<CustomFields[]>([]);
  const [isPromoCodeValid, setIsPromoCodeValid] = useState("");
  const [customFieldValues, setFieldValues] = useState(
    customFields?.map((field) => ({
      id: field.id,
      value: "",
    })) ?? []
  );
  useEffect(() => {
    initFunction();
    const getTimezoneFromAPI = async () => {
      try {
        // Fetch user's timezone from WorldTime API
        const response = await fetch("https://worldtimeapi.org/api/ip");
        const data = await response.json();
        return data.timezone;
      } catch (error) {
        console.error("Error fetching timezone:", error);
        // Default timezone in case of error
        return "UTC";
      }
    };

  

    const convertToLocalDateTime = async () => {
      try {
        const tz = await getTimezoneFromAPI();

        const endDateTime = new Date(end_date);
        const eventDateTime = new Date(event_date);

        // Convert UTC to local date and time
        const localEndDate = endDateTime.toLocaleString("en-US", {
          timeZone: tz,
        });
        const localEventDate = eventDateTime.toLocaleString("en-US", {
          timeZone: tz,
        });

        setLocalEndDate(localEndDate);
        setLocalEventDate(localEventDate);
        setTimeZone(tz);
      } catch (error) {
        console.error("Error converting to local date time:", error);
      }
    };

    convertToLocalDateTime();
  }, [end_date, event_date]);

  const getAd = async () => {
    const adRes = await apiGetRequest(`${urls.advertisement}`, {
      auth: undefined,
      content_type: ContentTypeEnum.APPLICATION_JSON,
    });

    if (adRes?.code === "00") {
      setAdData(adRes.data);
    }
  };

  function convertDateFormat(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const handlePublish = async () => {
    const eventFormData = new FormData();
    eventFormData.append("event_name", eventDetails?.event_name ?? "");
    eventFormData.append(
      "event_date",
      convertDateFormat(eventDetails?.event_date ?? "")
    );
    eventFormData.append(
      "end_date",
      convertDateFormat(eventDetails?.event_date ?? "")
    );
    eventFormData.append("event_description", eventDetails?.description ?? "");
    eventFormData.append("venue", eventDetails?.venue ?? "");
    eventFormData.append("visibility", eventDetails?.visibility ?? "");
    eventFormData.append("display_status", "published");

    if (eventDetails?.id) {
      if (
        eventDetails?.display_status == DisplayStatusEnum.draft ||
        !eventDetails?.group_id
      ) {
        const createGroupRes = await apiPostRequest(
          "/group/add",
          {
            group_name: eventDetails?.event_name ?? "",
            description: `This is the group for the ${
              eventDetails?.event_name ?? ""
            } event`,
            visibility: eventDetails?.visibility ?? "",
          },
          {
            auth: userDetails.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );
        if (createGroupRes?.code === "00") {
          eventFormData.append("group_id", createGroupRes.data.id.toString());
        } else {
          toast(createGroupRes.msg ?? "Failed", {
            type: ErrorSeverityEnum.error,
          });
          return;
        }
      }
      eventFormData.append(
        "event_show_date",
        convertDateFormat(eventDetails?.event_date ?? "")
      );

      const res = await apiPutRequest(
        `${urls.events}update/${eventDetails?.id}`,
        eventFormData,
        {
          auth: userDetails.access_token,
          content_type: ContentTypeEnum.MULTIPART_FORM,
        }
      );
      if (res?.code == "00") {
        toast(res.msg ?? "Event Published", {
          type: ErrorSeverityEnum.success,
        });
        navigate("/event-admin");
      } else {
        toast(res.msg ?? "Failed", {
          type: ErrorSeverityEnum.error,
        });
      }
    }
  };

  const getUserData = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.LoginUser, true);
      }
    }
  };

  const initFunction = async () => {
    const pathname = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);

    // If URL is in format "/e/:purl"
    if (pathname.startsWith("/e/")) {
      const purl = pathname.split("/")[2];
      const res = await apiGetRequest(`${urls.events}${purl}`);
      if (res?.data != null) {
        setEventDetails(res.data);
        setEventPoster(res.data.poster);
        setEventTickets(res.data.tickets ?? []);
        setEventDate(res.data.event_date);
        setEndDate(res.data.end_date);
        setEventTime(res.data.end_date);
        setCurrency(res.data.currency);
        setTicketList(eventTickets.map(() => 0));
        if ((res.data.custom_fields ?? []).length > 0) {
          setCustomFields(res.data.custom_fields);
        }

        if ((res.data.tickets ?? []).length > 0) {
          setSelectedTicket(res.data.tickets[0].id);
        }
      }
    } else if (searchParams.has("eventId")) {
      const eventId = searchParams.get("eventId");
      const promoCode = searchParams.get("promoCode");

      // Fetch event details using eventId
      const res = await apiGetRequest(`${urls.events}${eventId}`);
      if (res?.data != null) {
        setEventDetails(res.data);
        setEventPoster(res.data.poster);
        setEventTickets(res.data.tickets ?? []);
        setEventDate(res.data.event_date);
        setEndDate(res.data.end_date);
        setEventTime(res.data.end_date);
        setCurrency(res.data.currency);
        setTicketList(eventTickets.map(() => 0));
        if ((res.data.custom_fields ?? []).length > 0) {
          setCustomFields(res.data.custom_fields);
        }

        if ((res.data.tickets ?? []).length > 0) {
          setSelectedTicket(res.data.tickets[0].id);
        }
      }
    }

    if (searchParams.has("promCode")) {
      const promCode = searchParams.get("promCode");
      setPromoCode(promCode ?? "");
      // applyPromoCodeFunct(promCode ?? "", userDetailsObject);
    }
  };

  useEffect(() => {
    initFunction();
    getAd();

    getUserData();

    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          getAd();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);

  const resetForm = () => {
    setUserWallets([]);
    setOpen(false);
    setUserDetails(null);
    setQasaPin("");
    setPaymentMode("");
    setValue(1);
    setRetryCount(1);
    setStripeElementLoaded(false);
    setShowStripePayment(false);
    setPromoCodeUsed(false);
    setChecked(false);
    setDiscountedAmount("");
    setPromoCode("");
    setEmail("");
    setCheckout(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setPaymentMode(event.target.value);
  };
  const handleNetworkChange = (event: SelectChangeEvent) => {
    setNetwork(event.target.value);
  };

  const handleNumberInputChange = (val: number) => {
    setValue(val);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sanitizeTimezone = (timezone: string): string => {
    return timezone.replace(",", "/").replace(" ", "");
  };

  const extractTimeFromDate = (dateStr: string | number | Date) => {
    if (!eventDetails?.event_timezone) {
      return "";
    }

    const sanitizedTimezone = sanitizeTimezone(eventDetails.event_timezone);
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: sanitizedTimezone,
      timeZoneName: "short",
    };

    try {
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const formattedDateParts = formatter.formatToParts(date);

      let timeString = "";
      let timeZone = "";

      formattedDateParts.forEach(({ type, value }) => {
        if (type === "hour" || type === "minute" || type === "second") {
          timeString += value.padStart(2, "0") + ":";
        }
        if (type === "timeZoneName") {
          timeZone = value;
        }
      });

      timeString = timeString.slice(0, -1);

      return `${timeString} (${timeZone})`;
    } catch (error) {
      console.error("Invalid timezone:", sanitizedTimezone);
      return "Invalid timezone";
    }
  };

  const handleCheckout = async () => {
    // check if the ticket stuff is done.
    // Here, I will send the list of the tickets purchased. Both names and amount.
    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        const { country, ...rest } = userDetailsObject;

        setUserDetails(userDetailsObject);
        const res = await apiGetRequest(`${urls.wallet}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        if (res?.code === "00") {
          setUserWallets(res.data);
          handleOpen();

          navigate("/checkout", {
            state: {
              purchasedTickets: purchasedTicket,
              eventDetailz: eventDetails,
              ticket_subTotal: actualAmount,
              ticket_currency: currency,
              ticket_fees: fees,
              user_phone: userDetailsObject.phone,
              user_country: userDetailsObject.country,
              user_wallet: res.data[0],
              isPromoCodeUsed: promoCodeUsed,
              tickets_data: ticketData,
              ticket_promo_code: promoCode,
              user_data: userDetailsObject,
            },
          });
        }
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.LoginUser, true);
      }
    } else {
      // EventRegister.emit(EventsEnum.LoginUser, true);
      navigate("/checkout", {
        state: {
          purchasedTickets: purchasedTicket,
          eventDetailz: eventDetails,
          ticket_subTotal: actualAmount,
          ticket_currency: currency,
          ticket_fees: fees,
          user_phone: null,
          user_country: null,
          user_wallet: null,
          isPromoCodeUsed: promoCodeUsed,
          tickets_data: ticketData,
          ticket_promo_code: promoCode,
          user_data: null,
        },
      });
    }
    // setCheckout(true);
  }


  function capitalizeText(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  const applyPromoCodeFunct = async () => {
    if (ticketData.length > 0 && promoCode !== "") {
      const promoCodeData = {
        promo_code: promoCode,
        tickets_data: ticketData,
      };
      const resp = await apiPostRequest(
        "/events/apply_promo_code",
        promoCodeData,
        {
          auth: userDetails?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        }
      );

      if (resp.code == "00") {
        // toast(resp.msg ?? "Action Successful", {
        //   type: ErrorSeverityEnum.success,
        // });actualAmount fees setActualAmount setTicketsFee
        setDiscountedAmount(`${resp.data.discounted_amount}`);
        setPromoCodeUsed(true);
        if (resp.data.discount_value === 100) {
          setTicketsFee(0);
          setActualAmount(resp.data.discounted_amount);
        } else {
          setActualAmount(resp.data.discounted_amount - fees);
        }
        setIsPromoCodeValid("valid");
        setSubTotal(resp.data.discounted_amount);
      } else {
        setIsPromoCodeValid("invalid");
        // toast(resp.msg ?? "Action failed", {
        //   type: ErrorSeverityEnum.error,
        // });
      }
    }
  };

  function handleTicketCount(
    index: number,
    value: string,
    ticket: Ticket
  ): void {
    const updatedCounts = [...ticketList];
    updatedCounts[index] = parseInt(value, 10);

    const ticketInfo: TicketInfo = {
      ticket_id: ticket.id,
      quantity: parseInt(value, 10),
    };

    const ticketPurchased: PurchasedTicket = {
      ticket_id: ticket.id,
      ticket_name: ticket.ticket_name,
      ticket_price: ticket.price,
      ticket_currency: ticket.currency,
    };

    const existingTicketIndex = ticketData.findIndex(
      (data) => data.ticket_id === ticketInfo.ticket_id
    );

    if (existingTicketIndex !== -1) {
      const updatedTicketData = [...ticketData];
      updatedTicketData[existingTicketIndex] = ticketInfo;

      setTicketData(updatedTicketData);
    } else {
      setTicketData((prevTicketData) => [...prevTicketData, ticketInfo]);
    }

    // Update Purchased Ticket
    const existingPurchasedTicketIndex = purchasedTicket.findIndex(
      (data) => data.ticket_id === ticketPurchased.ticket_id
    );

    if (existingPurchasedTicketIndex !== -1) {
      const updatedPurchasedTicket = [...purchasedTicket];
      updatedPurchasedTicket[existingPurchasedTicketIndex].ticket_price +=
        ticketPurchased.ticket_price;
      setPurchasedTicket(updatedPurchasedTicket);
    } else {
      setPurchasedTicket((prevPurchasedTicket) => [
        ...prevPurchasedTicket,
        ticketPurchased,
      ]);
    }

    const hasTickets = updatedCounts.some((count) => count > 0);

    const newSubTotal = updatedCounts.reduce((total, count, i) => {
      return total + count * eventTickets[i].price;
    }, 0);

    const newFeeTotal = updatedCounts.reduce((total, count, i) => {
      return total + count * eventTickets[i].fee;
    }, 0);

    const actualAmount = newSubTotal + newFeeTotal;

    setActualAmount(newSubTotal);
    setTicketsFee(newFeeTotal);
    setTicketCounts(hasTickets);
    setTicketList(updatedCounts);
    setSubTotal(actualAmount);
  }

  function handleFieldChange(id: number, value: string) {
    setFieldValues((prevValues) =>
      prevValues.map((field) => (field.id === id ? { ...field, value } : field))
    );
  }

  const formattedDate = eventDetails?.event_date
    ? new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).format(new Date(eventDetails.event_date))
    : "Date not available";

  return (
    <>
      <MetaTags
        title={eventDetails?.event_name}
        purl={eventDetails?.purl}
        description={eventDetails?.description}
        image={eventDetails?.poster}
      />
      <Modal
        open={showPaymentHubtel}
        onClose={() => {
          setShowHubtelPayment(false);
          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, backgroundColor: "#f7f8fb" }}>
          {eventDetails && (
            // && userWallets.length > 0
            <Row mainAxisAlignment="space-between">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {`Purchase "${eventDetails.event_name}" ticket)`}
              </Typography>
              <Container
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </Container>
            </Row>
          )}
          <iframe
            title="urlRenderer"
            src={hubtelUrlToRender}
            width="100%"
            height="500px"
            frameBorder="0"
          ></iframe>
        </Box>
      </Modal>
      <>
        <Modal
          open={showPaymentStripe}
          onClose={() => {
            setShowStripePayment(false);
            resetForm();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {eventDetails && (
              // userWallets.length > 0 &&
              <Row mainAxisAlignment="space-between">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {`Purchase "${eventDetails.event_name}" ticket)`}
                </Typography>
                <Container
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </Container>
              </Row>
            )}
            {!stripeElementLoaded && (
              <Container
                sx={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  margin: "2rem 0",
                }}
              >
                <CircularProgress color="inherit" />
              </Container>
            )}
            <Elements stripe={stripePromise} options={stripeOptions}>
              <CheckoutForm
                clientSecret={stripeOptions.client_secret}
                onReady={() => setStripeElementLoaded(true)}
              />
            </Elements>
          </Box>
        </Modal>

        <FullWidthColumn>
          {eventDetails && (
            <section className="event-details-section">
              <div className="event-image-flyer">
                <Box
                  // component="img"
                  sx={{
                    height: "50vh",
                    width: "100%",
                    objectFit: "cover",
                  }}
                >
                  <VaryingWidthImage
                    imageUrl={eventDetails.poster}
                    blurImageUrl={eventDetails.poster}
                    imageList={(eventDetails?.image_sliders ?? []).map(
                      (x: any) => x.image_link
                    )}
                    type="details_page"
                    disableSlider={false}
                  />
                </Box>
              </div>
              <div className="flex-container">
                <div className="flex-item">
                  <div className="events">
                    <HeaderGreenTopBar />
                    <h2 className="event-content-header">EVENT INFORMATION</h2>
                    <p className="event-content-sub-header">
                      Event Name<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {eventDetails.event_name}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Date<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {formattedDate}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Time<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {extractTimeFromDate(event_date)}{" "}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Address<br></br>
                      <span className="event-content-sub-info">
                        {eventDetails.venue}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Description<br></br>
                      <span className="event-content-sub-info">
                        {eventDetails.description ??
                          "Checkout this awesome event"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="vertical-separator"></div>
                <div className="flex-item">
                  <div className="events">
                    <HeaderGreenTopBar />
                    <h2 className="event-content-header">EVENT PRICES</h2>

                    {eventTickets.map((ticket, index) => (
                      <div className="event-pricing-content">
                        <div className="pricing-info">
                          {ticket.ticket_name}
                          <br></br>
                          <span className="event-content-sub-info">
                            {ticket.price != 0 ? ticket.currency : ""}
                            {ticket.price != 0 ? ticket.price : "Free"}
                            &nbsp; + &nbsp;
                            {ticket.fee != 0 ? ticket.currency : ""}
                            {ticket.fee != 0 ? `${ticket.fee} Fee` : "0"}
                            &nbsp;
                          </span>
                        </div>
                        <div>
                          <input
                            type="number"
                            min={0}
                            className="event-input"
                            defaultValue={0}
                            onChange={(e) =>
                              handleTicketCount(index, e.target.value, ticket)
                            }
                          />
                        </div>
                      </div>
                    ))}

                    {eventDetails.display_status != DisplayStatusEnum.draft &&
                      eventDetails.has_promo_codes && (
                        <div className="promo-code">
                          <PromoCode
                            sx={{
                              "& fieldset": { border: "none" },
                              width: "80%",
                              maxWidth: "250px",
                            }}
                            InputProps={{
                              inputProps: {
                                style: {
                                  textAlign: "left",
                                  borderRadius: "100rem",
                                  background: "rgba(255, 255, 255, 0.694)",
                                  height: "20px",
                                },
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  {isPromoCodeValid !== null &&
                                    (isPromoCodeValid === "valid" ? (
                                      <CheckIcon style={{ color: "green" }} />
                                    ) : isPromoCodeValid === "invalid" ? (
                                      <CloseIcon style={{ color: "red" }} />
                                    ) : (
                                      ""
                                    ))}
                                </InputAdornment>
                              ),
                            }}
                            onBlur={applyPromoCodeFunct}
                            onChange={(e) => {
                              setPromoCode(e.target.value);
                            }}
                            margin="normal"
                            id="promoCode"
                            label="Promo Code"
                            name="promoCode"
                            // error={!isPromoCodeValid}
                            // helperText={!isValid ? "Invalid promo code" : ""}
                          />
                        </div>
                      )}

                    {customFields.map((custom_field, index) => (
                      <>
                        <div className="custom_title">
                          <strong>
                            {" "}
                            {capitalizeText(custom_field.field_name)}
                          </strong>
                        </div>
                        <StyledTextField
                          sx={{
                            "& fieldset": { border: "none" },
                            width: "100%",
                            maxWidth: "250px",
                            marginTop: "0px",
                            marginBottom: "5px",
                          }}
                          InputProps={{
                            inputProps: {
                              style: {
                                textAlign: "left",
                                borderRadius: "100rem",
                                background: "rgba(255, 255, 255, 0.694)",
                                height: "20px",
                              },
                            },
                          }}
                          onChange={(e) => {
                            handleFieldChange(custom_field.id, e.target.value);
                          }}
                          margin="normal"
                          label={custom_field.field_name}
                          name={custom_field.field_name}
                        />
                      </>
                    ))}

                    <div className="amount-total">
                      <strong>Sub Total</strong>
                      <br></br>
                      {currency}
                      {subTotal.toFixed(2)}
                    </div>

                    {eventDetails.display_status != DisplayStatusEnum.draft && (
                      <div className="submit-btn">
                        {checkout ? (
                          ""
                        ) : ticketCounts ? (
                          <button
                            type="submit"
                            className="event-submit-btn"
                            onClick={handleCheckout}
                          >
                            Checkout
                          </button>
                        ) : (
                          <button
                            type="submit"
                            disabled
                            className="event-submit-btn-disabled"
                            onClick={handleCheckout}
                          >
                            Checkout
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
          {eventDetails?.display_status == DisplayStatusEnum.draft && (
            <Row spacing={40} sx={{ marginBottom: "40rem", width: "100%" }}>
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  marginTop: "5rem",
                  height: "48px",
                  borderRadius: "100rem",
                  borderColor: "#0CAF6D",
                  color: "#0CAF6D",
                }}
                // disabled={!allValuesFilled()}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
              {
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    marginTop: "5rem",
                    height: "48px",
                    borderRadius: "100rem",
                    backgroundColor: "#0CAF6D",
                  }}
                  onClick={() => handlePublish()}
                >
                  Publish
                </Button>
              }
            </Row>
          )}
        </FullWidthColumn>
      </>
    </>
  );
}

export default EventDetails;

const PromoCode = styled(TextField)({
  "& .MuiInputLabel-root": {
    textAlign: "left",
  },
  "& .MuiInputLabel-shrink": {
    margin: "0 auto",
    position: "absolute",
    left: "0",
    top: "-3px",
    width: "150px",
    background: "none",
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& legend ": {
      display: "none",
    },
  },
});
